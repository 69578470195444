import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardSideNavBar from "../side-navbar/DashboardSideNavBar.js";
import { Route, Routes, useNavigate } from "react-router-dom";
import rapport_icon from "../../StyleCSS/icons/doubleGraph.png";
import arkiv_icon from "../../StyleCSS/icons/arkiv.png";
import fixed_income_icon from "../../StyleCSS/icons/rantor.png";
import equity_icon from "../../StyleCSS/icons/aktier.png";
import alternative_icon from "../../StyleCSS/icons/alternativa.png";
import fees_icon from "../../StyleCSS/icons/avgifter2.png";
import home_icon from "../../StyleCSS/icons/home.png";
import download_icon from "../../StyleCSS/icons/download_icon_2.png";
import esg_icon from "../../StyleCSS/icons/leaf.png";
import "../../StyleCSS/dashboardLanding.css";
import PBIReport from "./PBIReport.js";
import { getPBICredentials } from "../../reducers/PBICredSlice.js";
import ExportDataPage from "./ExportData.js";

const ICONS = {
  archive: arkiv_icon,
  arkiv: arkiv_icon,
  rapport_icon: rapport_icon,
  dubbelgraf: rapport_icon,
  aktier: equity_icon,
  startsida: home_icon,
  räntor: fixed_income_icon,
  alternativa: alternative_icon,
  avgifter: fees_icon,
  download_icon: download_icon,
  esg: esg_icon,
};

const DEFAULT_ICON = rapport_icon; //equity_icon;

const MENU_ITEMS = [
  {
    pathName: "Exportera data",
    route_path: "exportData",
    icon: download_icon,
    subItems: false,
  },
];

function dashboardLanding(props) {
  const dispatch = useDispatch();
  //   const pages = useSelector((state) => state.PBICred.pages);
  const [menuItems, setMenuItems] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const reqStatus = useSelector((state) => state.PBICred.status);
  const [isError, setIsError] = useState(false);
  const [noReport, setNoReport] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);

  useEffect(() => {
    loadReport();
  }, [tokenExpired]);

  function loadReport() {
    setLoaded(false);
    // get PBI credentials which includes pages
    dispatch(getPBICredentials())
      .then((resp) => {
        if (
          resp.meta.requestStatus === "fulfilled" &&
          Array.isArray(resp.payload.reportPages)
        ) {
          // if successful, create menu items from pages including myaccount item.
          let items = resp.payload.reportPages
            .map((p) => {
              return {
                pathName: p.displayName,
                route_path: `rapport/${p.name}`,
                icon: ICONS[p.icon] ? ICONS[p.icon] : DEFAULT_ICON, // set defualt icon here
                subItems: false,
              };
            })
            .concat([...MENU_ITEMS]);

          // set menu items
          setMenuItems(items);

          //navigate to the first page
          if (items.length) {
            setNoReport(false);
            navigate("/dashboard/" + items[0].route_path);
          } else {
            setNoReport(true);
          }
        }
      })
      .finally(() => {
        // when everything is loaded we can show the menu and
        setLoaded(true);
        setTokenExpired(false);
      });
  }

  useEffect(() => {
    if (reqStatus === "rejected") {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [reqStatus]);

  return (
    <div className="dashboardLanding">
      {loaded ? <DashboardSideNavBar routes={menuItems} /> : null}
      {noReport ? (
        <h2 className="NoReportError">Du har för tillfället ingen rapport</h2>
      ) : isError ? (
        <h2 className="NoReportError">
          {" "}
          Kunde inte hämta rapport, kontakta din rådgivare eller försök igen
          senare{" "}
        </h2>
      ) : (
        <div className="dashboardHomeContainer">
          <Routes>
            <Route
              path="/rapport/:pageName/*"
              element={
                <PBIReport
                  reloadReport={loadReport}
                  tokenExpired={tokenExpired}
                  setTokenExpired={setTokenExpired}
                />
              }
              key={window.location.pathname + "/rapport"}
            />
            <Route
              path="/exportData/*"
              element={<ExportDataPage />}
              key={window.location.pathname + "/exportData"}
            />
            {/* <Route
                            path="/arkiv/*"
                            element={<Archive/>}
                            key={window.location.pathname + "/arkiv"}
                        /> */}
          </Routes>
        </div>
      )}
    </div>
  );
}

// function MyAccount(props) {
//   return <div>kontosida här</div>;
// }

// function Archive(props) {
//   return <div>arkivsida här</div>;
// }

export default dashboardLanding;
